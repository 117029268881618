import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { CommonMetaHeader } from '../custom/components/common-meta-header';
import ContactNum from '../custom/components/contact-num';
import { JsonLDTag, JsonLDType } from '../custom/components/json-ld-tag';
import PageBackBtn from '../custom/components/page-back-btn';
import TwoPassRender from '../custom/components/two-pass-render';
import { CustomGtagCategory } from '../custom/constants/gtag-category';

export const Head = () => {
  return (
    <>
      <CommonMetaHeader />
      <JsonLDTag
        type={JsonLDType.BreadcrumbList}
        breadcrumb={[{ name: 'Singapore Funeral Directory', url: 'https://singaporefuneraldirectory.org' }]}
      />
    </>
  );
};

const FuneralDirectorPage = () => {
  return (
    <TwoPassRender>
      <Box padding={1}>
        <Box sx={{ maxWidth: 800, margin: 'auto' }}>
          <Box mt={3}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                WebkitAlignSelf: 'center',
              }}
            >
              For Funeral Director
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                WebkitAlignSelf: 'center',
              }}
            >
              Why join Singapore Funeral Directory
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography component="div">
              <ol
                style={{
                  maxWidth: 800,
                  margin: 'auto',
                  paddingInlineStart: '20px',
                }}
              >
                <li>Help the families during their difficult times</li>
                <li>
                  Singapore Funeral Directory is NOT a funeral company, there is
                  no conflict of interest when you join SFD
                </li>
                <li>
                  Use our technology to meet the needs of family and uphold the
                  standard of funeral services in Singapore
                </li>
              </ol>
            </Typography>
          </Box>

          <Box mt={6}>
            <Typography component="div">
              <div>
                <span aria-hidden style={{ display: 'inline-block' }}>
                  Call&nbsp;
                </span>
                <span style={{ display: 'inline-block' }}>
                  <ContactNum
                    phoneNumber="92366269"
                    phoneNumGtagCategory={CustomGtagCategory.SFD_Phone_Link}
                  ></ContactNum>
                </span>
                <span style={{ display: 'inline' }}>
                  &nbsp;to join Singapore Funeral Directory now or you may&nbsp;
                  <a
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdB_FYBY8Jm5_HdbeJSRPeNbiA4BO0SP4DiVkFSJQTSNnWJjQ/viewform"
                    rel="noopener">
                    register your interest here
                  </a> and we will contact you within three working days.
                </span>
              </div>
            </Typography>
          </Box>
        </Box>

        <Box mt={4}>
          <Grid container sx={{ maxWidth: 800, margin: 'auto' }}>
            <PageBackBtn
              gtagCategory={CustomGtagCategory.Funeral_Director_Back}
            />
          </Grid>
        </Box>
      </Box>
    </TwoPassRender>
  );
};

export default FuneralDirectorPage;
