import * as React from 'react';
import { Typography } from '@mui/material';
import { CustomGtagCategory } from '../../custom/constants/gtag-category';
import BackIcon from '@mui/icons-material/ChevronLeftSharp';
import GtagNavigate from '../../custom/components/gtag-navigate';

export interface PageBackBtnProps {
  gtagLabel?: string;
  gtagCategory?: CustomGtagCategory;
  navigateTo?: string;
}

const PageBackBtn = (props: PageBackBtnProps) => {
  return (
    <Typography sx={{ marginInlineStart: '-8px' }}>
      <GtagNavigate
        category={props.gtagCategory}
        label={props.gtagLabel}
        to={props.navigateTo || `/`}
      >
        <BackIcon sx={{ verticalAlign: 'middle' }} />
        <span>Back</span>
      </GtagNavigate>
    </Typography>
  );
};

export default PageBackBtn;
