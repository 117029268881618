import React, { useState, useEffect, ReactNode } from 'react';

interface TwoPassRenderProps {
  children: ReactNode;
}

/**
 * This is to handle the weird CSS issue when loading a new page
 *
 * https://github.com/gatsbyjs/gatsby/discussions/17914
 */
const TwoPassRender = (props: TwoPassRenderProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (isMounted) {
    return <>{props.children}</>;
  }

  return null;
};

export default TwoPassRender;
